<template>
  <div class="dashboard p-5" id="dementie-information">
    <div class="row">
      <div class="col-sm-12 down">
        <h1 class="intro-section-title">Leven met Dementie - Inkomen en Vermogen</h1>
        <div class="row">
          <p class="intro-section-text">
            Dit overzicht gaat over gegevens uit de jaren 2017-2022. Onderstaand dashboard laat informatie over het inkomen en vermogen van mensen met dementie zien. Er wordt informatie gegeven over hoe de verdeling van inkomen is tussen mensen met dementie en er wordt informatie weergegeven over de zorgkantoren/dementienetwerken met een hoog percentage mensen met dementie dat een laag inkomen (minder dan 15000 euro) heeft. 
          </p>
        </div>
      </div>
    </div>

    <div class="row" v-if="selectedLevel !== 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="`Verdeling inkomen mensen met dementie in ${selectedYear}`" :hovertext="'Vier inkomenscategorieën op basis van gestandaardiseerd huishoudensinkomen. Dit is het besteedbaar inkomen (nettobedrag), gecorrigeerd voor samenstelling van het huishouden. Huishoudens met een ontbrekend of negatief inkomen zijn ingedeeld in de laagste categorie inkomen. Bron: CBS microdata.'" 
        :x="zorgkantoorregionaam" :y="inkomen" :z="percentage.map(value => `${value}%`)"
          :xtitle="'Region naam'" :ytitle="'Inkomen groep'" :ztitle="'Percentage'"
          :tableHeight="selectedLevel === 'dementienetwerk' ? 4000 : 1000">
          <template slot="card-header">Verdeling inkomen mensen met dementie in {{selectedYear}}</template>
          <div slot="card-body">
            <!--Onderstaande grafiek toont de verdeling van inkomen van mensen met dementie. Het percentage mensen met een inkomen onder de 15.000 euro neemt af over de jaren voor vrijwel elke regio.-->
            <div class="row" align-items-center justify-content-center>
              <div class="col-sm-8">
                <p class="paragraph">Onderstaande grafiek toont de verdeling van inkomen van mensen met dementie. Het percentage mensen met een inkomen onder de 15.000 euro neemt af over de jaren voor vrijwel elke regio.</p>
                <stacked-bar-chart-colors :ytitle="'% in inkomensgroep'" v-if="load.graphOne"
                :names="['< €15.000', '€15.000-€20.000', '€20.000-€30.000', '€30.000 +']"
                :x="[zorgkantoorregionaam_10k, zorgkantoorregionaam_20k, zorgkantoorregionaam_30k, zorgkantoorregionaam_40k]"
                :y="[inkomen_10k, inkomen_20k, inkomen_30k, inkomen_40k]"
                :ticksuffix="'%'"/>
              </div>
              <div class="col-sm-4">
                <p class="paragraph">Onderstaande grafiek toont de verdeling van inkomen van mensen met dementie. Het percentage mensen met een inkomen onder de 15.000 euro neemt af over de jaren voor vrijwel elke regio.</p>
                <stacked-bar-chart-colors-multi v-if="load.graphSeven"
                  :marginBottom="100"
                  :title="'Inkomensverdeling door de tijd'"
                  :name1="'< €15.000'"
                  :x1="zorgkantoorregionaam_10k_ddt"
                  :xx1="jaar_10k_ddt"
                  :y1="inkomen_10k_ddt"
                  :name2="'€15.000-€20.000'"
                  :x2="zorgkantoorregionaam_20k_ddt"
                  :xx2="jaar_20k_ddt"
                  :y2="inkomen_20k_ddt"
                  :name3="'€20.000-€30.000'"
                  :x3="zorgkantoorregionaam_30k_ddt"
                  :xx3="jaar_30k_ddt"
                  :y3="inkomen_30k_ddt"
                  :name4="'€30.000 +'"
                  :x4="zorgkantoorregionaam_40k_ddt"
                  :xx4="jaar_40k_ddt"
                  :y4="inkomen_40k_ddt"
                  :showlegend="false"/>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'landelijk'">
          <div class="col-sm-12 down">
            <card :cardId="'Verdeling inkomen mensen met dementie'" :hovertext="'Vier inkomenscategorieën op basis van gestandaardiseerd huishoudensinkomen. Dit is het besteedbaar inkomen (nettobedrag), gecorrigeerd voor samenstelling van het huishouden. Huishoudens met een ontbrekend of negatief inkomen zijn ingedeeld in de laagste categorie inkomen. Bron: CBS microdata.'"
              :x="jaar" :y="inkomen" :z="percentage.map(value => `${value}%`)"
              :xtitle="'Jaar'" :ytitle="'Inkomen groep'" :ztitle="'Percentage'"
              :tableHeight="600">
                <template slot="card-header">Verdeling inkomen mensen met dementie</template>
                <div slot="card-body">
                  Onderstaande grafiek geeft het landelijke niveau weer van de inkomensverdeling van mensen met dementie. In vrijwel elke regio daalt het percentage mensen met een inkomen onder de 15.000 euro door de jaren heen.
                    <stacked-bar-chart-colors-multi v-if="load.graphSeven" :tickangle="0"
                    :isLandelijk='false'
                    :height= "360"
                    :marginBottom="100"
                    :marginTop="50"
                    :name1="'< €15.000'"
                    :x1="zorgkantoorregionaam_10k_ddt"
                    :xx1="jaar_10k_ddt"
                    :y1="inkomen_10k_ddt"
                    :name2="'€15.000-€20.000'"
                    :x2="zorgkantoorregionaam_20k_ddt"
                    :xx2="jaar_20k_ddt"
                    :y2="inkomen_20k_ddt"
                    :name3="'€20.000-€30.000'"
                    :x3="zorgkantoorregionaam_30k_ddt"
                    :xx3="jaar_30k_ddt"
                    :y3="inkomen_30k_ddt"
                    :name4="'€30.000 +'"
                    :x4="zorgkantoorregionaam_40k_ddt"
                    :xx4="jaar_40k_ddt"
                    :y4="inkomen_40k_ddt"
                    :showlegend="true"/>
                  </div>
                </card>
              </div>
            </div>

    <!-- <div class="row">
      <div class="col-sm-12 down">
        <card :hovertext="'Data op basis van gestandaardiseerd huishoudensinkomen. Huishoudens met een ontbrekend of negatief inkomen zijn ingedeeld in deze laagste categorie inkomen'">
          <template slot="card-header" >Percentage mensen met dementie met inkomen minder dan 15000 euro in {{selectedYear}}</template>
          <div slot="card-body">
            Deze grafiek toont het percentage mensen met dementie per zorgkantoor/dementienetwerk met een inkomen lager dan 15k per jaar. In 2019 wonen, net als in 2018 en 2017, in de zorgkantoorregio’s en dementienetwerken in Amsterdam en Rotterdam relatief veel mensen met dementie met een inkomen van 15.000 euro per jaar of minder.
            <horizontal-bar-chart v-if="load.graphOne" :xtitle="'% onder de armoedegrens'" :x="inkomen_10k_top6" :y="zorgkantoorregionaam_10k_top6" :marginleft="200" />
          </div>
        </card>
      </div>
    </div> -->

    <Footer :adclogo="adclogo" :erasmuslogo="erasmuslogo"/>
  </div>
</template>


<script>


import Card from '../components/Card'
// import HorizontalBarChart from '../components/HorizontalBarChart'
import StackedBarChartColors from '../components/StackedBarChartColors'
import StackedBarChartColorsMulti from '../components/StackedBarChartColorsMulti'
import { mapGetters } from 'vuex'
import Footer from '../components/Footer'
import adclogo from '@/assets/ADC full logo.png'
import erasmuslogo from '@/assets/erasmuslogo.png'
import VueMeta from 'vue-meta'


export default {
  mixins: [VueMeta],
  metaInfo: {
    title: 'Leven met Dementie - Inkomen en Vermogen',
  },
  components: { Card,  StackedBarChartColors, StackedBarChartColorsMulti, Footer},
  data () {
    return {
      adclogo: adclogo,
      erasmuslogo: erasmuslogo,
      zorgkantoorregionaam_10k: [],
      inkomen_10k: [],
      zorgkantoorregionaam_20k: [],
      inkomen_20k: [],
      zorgkantoorregionaam_30k: [],
      inkomen_30k: [],
      zorgkantoorregionaam_40k: [],
      inkomen_40k: [],
      zorgkantoorregionaam_m40k: [],
      inkomen_m40k: [],
      zorgkantoorregionaam_10k_top6: [],
      inkomen_10k_top6: [],
      load: {
        graphOne: false,
        graphSeven: false,
      },
      zorgkantoorregionaam_10k_ddt: [],
      inkomen_10k_ddt: [],
      jaar_10k_ddt: [],
      zorgkantoorregionaam_20k_ddt: [],
      inkomen_20k_ddt: [],
      jaar_20k_ddt: [],
      zorgkantoorregionaam_30k_ddt: [],
      inkomen_30k_ddt: [],
      jaar_30k_ddt: [],
      zorgkantoorregionaam_40k_ddt: [],
      inkomen_40k_ddt: [],
      zorgkantoorregionaam_m40k_ddt: [],
      inkomen_m40k_ddt: [],
      jaar_40k_ddt: [],

      zorgkantoorregionaam: [],
      inkomen: [],
      jaar:[],
      percentage: [],
    }
  },
  watch: {
    '$store.state.selectedLevel': function (level) {
      this.loadAllData(level)
    },
    '$store.state.selectedYear': function () {
      this.loadAllData(this.$store.state.selectedLevel)
    },
    '$store.state.selectedRegion': function () {
      this.loadAllData(this.$store.state.selectedLevel)
    }
  },
  computed: {
      ...mapGetters(['selectedRegion', 'selectedLevel', 'selectedYear'])
  },
  methods: {
    loadAllData (level) {
      if (level === 'zorgkantoor') {
        this.$http.get(`/api/zorgkantoor_inkomen/get_data/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            response.data.sort((a,b) => b.percentage-a.percentage)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
            this.zorgkantoorregionaam_10k = response.data.filter(x => x.inkomen =='0-14999').map(d => d.zorgkantoorregionaam)
            this.inkomen_10k = response.data.filter(x => x.inkomen =='0-14999').map(d => (d.percentage))
            this.zorgkantoorregionaam_20k = response.data.filter(x => x.inkomen =='15000-19999').map(d => d.zorgkantoorregionaam)
            this.inkomen_20k = response.data.filter(x => x.inkomen =='15000-19999').map(d => (d.percentage))
            this.zorgkantoorregionaam_30k = response.data.filter(x => x.inkomen =='20000-29999').map(d => d.zorgkantoorregionaam)
            this.inkomen_30k = response.data.filter(x => x.inkomen =='20000-29999').map(d => (d.percentage))
            this.zorgkantoorregionaam_40k = response.data.filter(x => x.inkomen =='30000+').map(d => d.zorgkantoorregionaam)
            this.inkomen_40k = response.data.filter(x => x.inkomen =='30000+').map(d => (d.percentage))

            response.data.sort((a,b) => a.percentage-b.percentage)
            this.zorgkantoorregionaam_10k_top6 = response.data.filter(x => x.inkomen =='0-14999').map(d => d.zorgkantoorregionaam).slice(-6)
            this.inkomen_10k_top6 = response.data.filter(x => x.inkomen =='0-14999').map(d => (d.percentage)).slice(-6)
            this.load.graphOne=true
          }
        ),
        this.$http.get(`/api/zorgkantoor_inkomen/get_data/?regio=${this.$store.state.selectedRegion}`).then(
          (response) => {
            response.data.sort((a,b) => a.jaar-b.jaar)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
            this.zorgkantoorregionaam_10k_ddt = response.data.filter(x => x.inkomen =='0-14999').map(d => d.zorgkantoorregionaam)
            this.inkomen_10k_ddt = response.data.filter(x => x.inkomen =='0-14999').map(d => (d.percentage))
            this.jaar_10k_ddt = response.data.filter(x => x.inkomen =='0-14999').map(d => d.jaar)
            this.zorgkantoorregionaam_20k_ddt = response.data.filter(x => x.inkomen =='15000-19999').map(d => d.zorgkantoorregionaam)
            this.inkomen_20k_ddt = response.data.filter(x => x.inkomen =='15000-19999').map(d => (d.percentage))
            this.jaar_20k_ddt = response.data.filter(x => x.inkomen =='15000-19999').map(d => d.jaar)
            this.zorgkantoorregionaam_30k_ddt = response.data.filter(x => x.inkomen =='20000-29999').map(d => d.zorgkantoorregionaam)
            this.inkomen_30k_ddt = response.data.filter(x => x.inkomen =='20000-29999').map(d => (d.percentage))
            this.jaar_30k_ddt = response.data.filter(x => x.inkomen =='20000-29999').map(d => d.jaar)
            this.zorgkantoorregionaam_40k_ddt = response.data.filter(x => x.inkomen =='30000+').map(d => d.zorgkantoorregionaam)
            this.inkomen_40k_ddt = response.data.filter(x => x.inkomen =='30000+').map(d => (d.percentage))
            this.jaar_40k_ddt = response.data.filter(x => x.inkomen =='30000+').map(d => d.jaar)

            this.jaar = response.data.map(d => d.jaar)
            this.zorgkantoorregionaam = response.data.map(d => d.zorgkantoorregionaam)
            this.inkomen = response.data.map(d => (d.inkomen))
            this.percentage = response.data.map(d => (d.percentage).toFixed(1))
            this.load.graphSeven=true
          }
        )
      }
      else if(level === 'landelijk'){
        this.$http.get(`/api/zorgkantoor_inkomen/get_data/?landelijk=True`).then(
          (response) => {
            response.data.sort((a,b) => a.jaar-b.jaar)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
            this.zorgkantoorregionaam_10k_ddt = response.data.filter(x => x.inkomen =='0-14999').map(d => d.zorgkantoorregionaam)
            this.inkomen_10k_ddt = response.data.filter(x => x.inkomen =='0-14999').map(d => (d.percentage))
            this.jaar_10k_ddt = response.data.filter(x => x.inkomen =='0-14999').map(d => d.jaar)
            this.zorgkantoorregionaam_20k_ddt = response.data.filter(x => x.inkomen =='15000-19999').map(d => d.zorgkantoorregionaam)
            this.inkomen_20k_ddt = response.data.filter(x => x.inkomen =='15000-19999').map(d => (d.percentage))
            this.jaar_20k_ddt = response.data.filter(x => x.inkomen =='15000-19999').map(d => d.jaar)
            this.zorgkantoorregionaam_30k_ddt = response.data.filter(x => x.inkomen =='20000-29999').map(d => d.zorgkantoorregionaam)
            this.inkomen_30k_ddt = response.data.filter(x => x.inkomen =='20000-29999').map(d => (d.percentage))
            this.jaar_30k_ddt = response.data.filter(x => x.inkomen =='20000-29999').map(d => d.jaar)
            this.zorgkantoorregionaam_40k_ddt = response.data.filter(x => x.inkomen =='30000+').map(d => d.zorgkantoorregionaam)
            this.inkomen_40k_ddt = response.data.filter(x => x.inkomen =='30000+').map(d => (d.percentage))
            this.jaar_40k_ddt = response.data.filter(x => x.inkomen =='30000+').map(d => d.jaar)

            this.jaar = response.data.map(d => d.jaar)
            this.zorgkantoorregionaam = response.data.map(d => d.zorgkantoorregionaam)
            this.inkomen = response.data.map(d => (d.inkomen))
            this.percentage = response.data.map(d => (d.percentage).toFixed(1))
            this.load.graphSeven=true
          }
        )
      }
      else {
        this.$http.get(`/api/dementienetwerk_inkomen/get_data/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            response.data.sort((a,b) => b.percentage-a.percentage)
            response.data = response.data.filter(x => x.dementienetwerknaam !=='Missing')
            this.zorgkantoorregionaam_10k = response.data.filter(x => x.inkomen =='0-14999').map(d => d.dementienetwerknaam)
            this.inkomen_10k = response.data.filter(x => x.inkomen =='0-14999').map(d => (d.percentage))
            this.zorgkantoorregionaam_20k = response.data.filter(x => x.inkomen =='15000-19999').map(d => d.dementienetwerknaam)
            this.inkomen_20k = response.data.filter(x => x.inkomen =='15000-19999').map(d => (d.percentage))
            this.zorgkantoorregionaam_30k = response.data.filter(x => x.inkomen =='20000-29999').map(d => d.dementienetwerknaam)
            this.inkomen_30k = response.data.filter(x => x.inkomen =='20000-29999').map(d => (d.percentage))
            this.zorgkantoorregionaam_40k = response.data.filter(x => x.inkomen =='30000+').map(d => d.dementienetwerknaam)
            this.inkomen_40k = response.data.filter(x => x.inkomen =='30000+').map(d => (d.percentage))

            response.data.sort((a,b) => a.percentage-b.percentage)
            this.zorgkantoorregionaam_10k_top6 = response.data.filter(x => x.inkomen =='0-14999').map(d => d.dementienetwerknaam).slice(-6)
            this.inkomen_10k_top6 = response.data.filter(x => x.inkomen =='0-14999').map(d => (d.percentage)).slice(-6)

            this.jaar = response.data.map(d => d.jaar)
            this.zorgkantoorregionaam = response.data.map(d => d.dementienetwerknaam)
            this.inkomen = response.data.map(d => (d.inkomen))
            this.percentage = response.data.map(d => (d.percentage).toFixed(1))
            this.load.graphOne=true
          }
        ),
        this.$http.get(`/api/dementienetwerk_inkomen/get_data/?netwerk=${this.$store.state.selectedRegion}`).then(
          (response) => {
            response.data.sort((a,b) => a.jaar-b.jaar)
            response.data = response.data.filter(x => x.dementienetwerknaam !=='Missing')
            this.zorgkantoorregionaam_10k_ddt = response.data.filter(x => x.inkomen =='0-14999').map(d => d.dementienetwerknaam)
            this.inkomen_10k_ddt = response.data.filter(x => x.inkomen =='0-14999').map(d => (d.percentage))
            this.jaar_10k_ddt = response.data.filter(x => x.inkomen =='0-14999').map(d => d.jaar)
            this.zorgkantoorregionaam_20k_ddt = response.data.filter(x => x.inkomen =='15000-19999').map(d => d.dementienetwerknaam)
            this.inkomen_20k_ddt = response.data.filter(x => x.inkomen =='15000-19999').map(d => (d.percentage))
            this.jaar_10k_ddt = response.data.filter(x => x.inkomen =='15000-19999').map(d => d.jaar)
            this.zorgkantoorregionaam_30k_ddt = response.data.filter(x => x.inkomen =='20000-29999').map(d => d.dementienetwerknaam)
            this.inkomen_30k_ddt = response.data.filter(x => x.inkomen =='20000-29999').map(d => (d.percentage))
            this.jaar_10k_ddt = response.data.filter(x => x.inkomen =='20000-29999').map(d => d.jaar)
            this.zorgkantoorregionaam_40k_ddt = response.data.filter(x => x.inkomen =='30000+').map(d => d.dementienetwerknaam)
            this.inkomen_40k_ddt = response.data.filter(x => x.inkomen =='30000+').map(d => (d.percentage))
            this.jaar_10k_ddt = response.data.filter(x => x.inkomen =='30000+').map(d => d.jaar)
            this.load.graphSeven=true
          }
        )
      }
    }
  },
  mounted () {
    this.loadAllData(this.selectedLevel)
  }
}


</script>

<style scoped>

.intro-section-title{
  font-weight: bold;
  margin-bottom: 2rem;
}

</style>